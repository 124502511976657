import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import RepairsRenovationsComponent from "../components/RepairsRenovationsComponent"
import PhotoGallery from "../components/PhotoGallery"
// import CallToAction from "../components/CallToAction"

const query = graphql`
  {
    contentfulRepairsGallery {
      images {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        title
        id
      }
    }
  }
`

export default function RepairsRenovationsPage() {
  const {
    contentfulRepairsGallery: { images: imageList },
  } = useStaticQuery(query)
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Waterscape Repairs & Renovations"
        description="Learn about Waterscape Repair & Renovation services provided by Andreatta Waterscapes - serving Rogue Valley since 1998."
      />
      <RepairsRenovationsComponent />
      <PhotoGallery imageList={imageList} />
      {/* <CallToAction end={true} /> */}
    </Layout>
  )
}
