import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SidebarTestimonials from "./UI/SidebarTestimonials"
import headerImg from "../assets/images/Pond_Skimmer_Repair_Andreatta_Waterscapes_Ashland_Oregon_compress.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-attachment: scroll;
  background-position: 50% 65%;
  color: white;
  padding: 7rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 50%;
    background-attachment: scroll;
  }
`

const useStyles = makeStyles(theme => ({
  h1: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 400,
    lineHeight: "1.2",
    letterSpacing: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  headerBox: {
    padding: "1.25rem",
    background: `rgba(0,0,0,0.5)`,
    color: "#fff",
    marginTop: "3rem",
    marginBottom: "2rem",
    borderRadius: 0,
    maxWidth: "60%",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      marginLeft: "auto",
    },
  },
  // h3: {
  //   fontSize: "3rem",
  //   color: theme.palette.common.muted,
  //   fontWeight: 600,
  //   [theme.breakpoints.only("xl")]: {
  //     fontSize: "3.5rem",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     fontSize: "2.5rem",
  //   },
  // },
  h4: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 400,
    marginBottom: ".35em",
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.65rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  body: {
    fontSize: "1.05rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".95rem",
    },
  },
  preHeader: {
    fontWeight: 500,
    marginBottom: ".25rem",
    color: "#646e73",
    fontSize: "1rem",
    textTransform: "uppercase",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: 0.8,
  },
  hr: {
    marginTop: ".6em",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "90%",
    marginBottom: "2.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.only("xl")]: {
      maxWidth: "100%",
    },
  },
  paper: {
    borderRadius: 0,
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
    padding: "1rem",
  },
  cardTitle: {
    fontSize: "1.75rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textDecoration: "none",
    transition: "0.5s",
    "&:hover": {
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.45rem",
    },
  },
  contactBtn: {
    // backgroundColor: "#fff",
    backgroundColor: "#1C1C1E",
    fontFamily: "Open Sans",
    textTransform: "capitalize",
    border: `1px solid ${theme.palette.secondary.main}`,
    // color: "#000",
    // color: 'rgba(0,0,0,0.8)',
    color: "rgba(235,235,245,.9)",
    fontWeight: 400,
    borderRadius: 0,
    fontSize: ".9575rem",
    padding: ".5rem 2rem",
    width: "100%",
    transition: "0.1s",
    "&:hover": {
      color: "#fff",
      border: `1px solid rgba(0,0,0,.08)`,
      backgroundColor: theme.palette.secondary.main,
      transform: "scale(1.01)",
    },
  },
  contactSection: {
    marginTop: "1rem",
    borderRadius: 0,
    border: `3px solid ${theme.palette.secondary.main}`,
    padding: "2rem 4rem",
    backgroundColor: "transparent",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 2rem",
    },
  },
  contactSecText: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.common.muted,
  },
  promoOfferPaper: {
    borderRadius: 0,
    padding: ".5rem",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-1.5rem",
      marginRight: "-1.5rem",
    },
  },
  promoBorder: {
    padding: "2.5rem",
    border: `3px solid ${theme.palette.common.muted}`,
    borderStyle: "dashed",
  },
  promoHeader: {
    color: theme.palette.common.muted,
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "1",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  promoSubHeader: {
    color: theme.palette.common.muted,
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  promoButton: {
    marginTop: "1rem",
    width: "100%",
    height: "3.125rem",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.common.muted,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.9)",
    },
  },
  textField: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  },
  floatLeft: {
    [theme.breakpoints.only("xl")]: {
      paddingLeft: "7rem",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "5rem",
    },
  },
  floatRight: {
    [theme.breakpoints.only("xl")]: {
      marginLeft: "-2rem",
    },
    [theme.breakpoints.only("lg")]: {
      marginLeft: "-2rem",
    },
  },
  li: {
    ...theme.typography.body1,
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
  ftImg: {
    borderRadius: 5,
    "& img": {
      objectFit: "contain",
    },
  },
}))

const StyledListItem = withStyles(theme => ({
  root: {
    transition: "transform 0.5s",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      transform: "scale(1.025)",
    },
    "&.Mui-selected": {
      backgroundColor: "#000",
      color: "#fff",
      fontSize: "1.01rem",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#000",
      color: "#fff",
      transform: "scale(1.025)",
    },
  },
}))(ListItem)

export default function RepairsRenovationsComponent() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  const location = useLocation()

  let CustomListItem = ({ to, primary }) => (
    <>
      <StyledListItem
        button
        component={Link}
        to={to}
        selected={to === location.pathname}
      >
        <ListItemText primary={primary} />
      </StyledListItem>
      <Divider />
    </>
  )

  let ItemSpacer = () => (
    <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
      <Grid container>
        <Divider
          style={{
            width: "90%",
            backgroundColor: 'transparent'
          }}
        />
      </Grid>
    </Grid>
  )

  return (
    <>
      <Background>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Paper className={classes.headerBox} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className={classes.h1}
                    align={matchesXS ? "center" : "left"}
                    gutterBottom
                  >
                    REPAIRS & RENOVATIONS
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Background>
      {/* <Container maxWidth="xl" style={{ paddingBottom: "5rem" }}> */}
      <Container maxWidth="xl" >
        <Grid container>
          <Grid item xs={12} md={8}>
            <Container maxWidth="lg">
              <Grid
                container
                style={{ paddingTop: "4rem" }}
                className={classes.floatLeft}
              >
                {/* <Grid item xs={12} lg={10} xl={11}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h2" className={classes.h3}>
                          Pond repairs & renovations
                        </Typography>
                        <Divider className={classes.hr} />
                      </Grid>
                    </Grid>
                  </Container>
                </Grid> */}
                <Grid item xs={12} lg={11} xl={11}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Assessment of existing pond
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          Did you just buy a house with a pond and need to know
                          how it works? Are you having trouble with your pond or
                          water feature and need to find out what is causing it?
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          We provide assessments of existing water features to
                          give you the answers you need to keep your water
                          feature running properly and ensuring a healthy
                          ecosystem for water gardens.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                        >
                          A pond assessment includes:
                        </Typography>
                        <ul style={{ marginBottom: "1rem" }}>
                          <li className={classes.li}>Water quality testing</li>
                          <li className={classes.li}>
                            Check biological load and filtering
                          </li>
                          <li className={classes.li}>
                            Oxygenation and circulation of the pond which plays
                            a critical role in keeping a healthy, balanced pond
                          </li>
                          <li className={classes.li}>
                            Inspect functioning of pumps, skimmers, lights and
                            other mechanical components
                          </li>
                          <li className={classes.li}>
                            Inspect for leaks and structural stability & safety
                          </li>
                          <li className={classes.li}>
                            Recommendations for addition or removal of any
                            aquatic plants
                          </li>
                          <li className={classes.li}>
                            Maintenance recommendations
                          </li>
                          <li className={classes.li}>
                            Aesthetic improvement recommendations if desired
                          </li>
                          <li className={classes.li}>
                            Answer any additional questions you may have about
                            your pond
                          </li>
                        </ul>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Leaks
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          There are a variety of reasons that a pond, waterfall,
                          or stream may begin to lose water. We will diagnose
                          the cause of your leak and assist in getting it back
                          up and running.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          You can send us pictures and we will discuss the issue
                          over the phone to determine if a repair visit is
                          needed. Sometimes you’ll be able to do the repair
                          yourself with a little guidance, and other times you
                          may need a professional to come help you out. Either
                          way, you’ll find us knowledgeable and ready to help.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Pump Replacements
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          Your pond pump is the heart of your water feature and
                          serves not just in providing the visual element of
                          moving water, but delivers the oxygenation that keeps
                          fish alive, especially in hot weather. It’s a vital
                          piece of the puzzle that keeps your pond, and fish,
                          healthy. It’s inevitable though that pumps wear out.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          We service both submersible and out of pond pumps and
                          carry a large variety of replacement pumps. We
                          warranty both the pump and our installation to ensure
                          that your water feature continues to run smoothly.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          If you have koi, we really want you to have an
                          aeration system. If your pump stops working, this can
                          save their lives while waiting for a new pump
                          installation.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Autofill
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          A convenient feature installed on many water features
                          is an autofill valve that allows the water to be
                          topped off and keeps it at its optimal level. An
                          autofill takes away the need to hand fill your water
                          feature with your garden hose. An autofill can also
                          prevent a pump replacement if your water level were to
                          get too low for a prolonged period and burn the pump
                          motor out.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          As with all things mechanical, autofills can break or
                          even just get out of adjustment. We can come out to
                          your house and replace or adjust it. We are also able
                          to retrofit your existing water feature with an
                          autofill and tie it into your water line or irrigation
                          system.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Filter Upgrade
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          If you are a koi fish enthusiast, you may find at some
                          point that your love of your koi has outgrown the
                          effectiveness of your current filtration system. We
                          highly recommend a filtration upgrade for our
                          customers where the size or number of fish in your
                          pond has outgrown the filter’s ability to keep the
                          fish and the pond’s ecosystem healthy.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          We are able to retrofit your pond with a pressure
                          filter. This will add more filtration and give you a
                          way to back flush some of the muck out of the pond.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Remodel
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                        >
                          There are many reasons that people decide to renovate
                          an existing pond or water feature.
                        </Typography>

                        <ul style={{ marginBottom: "1rem" }}>
                          <li className={classes.li}>
                            Do you want a bigger pond and more fish, or have
                            your Koi grown beyond your ponds capacity to safely
                            house them?
                          </li>
                          <li className={classes.li}>
                            Would you like to have a louder waterfall?
                          </li>
                          <li className={classes.li}>
                            Does your water feature need a facelift or have
                            problems that are bigger than a simple fix?
                          </li>
                        </ul>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          We can help evaluate what the existing problems are
                          and design a plan for a complete remodel or even
                          partial upgrades such as filtering or lighting. We
                          want everyone to LOVE their water feature and we’re
                          ready to help you take yours to the next level in
                          completing your custom outdoor living space.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container
              maxWidth={matchesMD ? "xl" : "xs"}
              style={{
                paddingTop: "6rem",
                paddingBottom: matchesSM ? "5rem" : undefined,
              }}
              className={classes.floatRight}
            >
              <Grid container direction="row" justifyContent="center">
                {/* <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={20}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          component={Link}
                          to="/new-installations/"
                          className={classes.cardTitle}
                          gutterBottom
                        >
                          Our Waterscape Services
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <List
                          component="nav"
                          aria-label="secondary mailbox folders"
                          style={{ margin: "-1rem" }}
                        >
                          <Divider />
                          <CustomListItem
                            to="/new-installations/"
                            primary="New Installations"
                          />
                          <CustomListItem
                            to="/repairs-renovations/"
                            primary="Repairs & Renovations"
                          />
                          <CustomListItem
                            to="/maintenance/"
                            primary="Maintenance"
                          />
                          <CustomListItem
                            to="/outdoor-living/"
                            primary="Outdoor Living"
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> */}

                {/* <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Paper className={classes.promoOfferPaper} elevation={0}>
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.promoBorder}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.promoHeader}
                        >
                          FREE Download
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.promoSubHeader}
                          paragraph
                          align="center"
                        >
                          HOW TO MAINTAIN YOUR BACKYARD POND
                        </Typography>
                        <form>
                          <input
                            type="hidden"
                            name="form-name"
                            value="Newsletter Signup"
                          />

                          <TextField
                            className={classes.textField}
                            placeholder="Your email"
                            variant="outlined"
                            id="Email Address"
                            name="Email Address"
                          />
                          <Button
                            type="submit"
                            color="inherit"
                            variant="contained"
                            disableElevation={true}
                            className={classes.promoButton}
                          >
                            Get it!
                          </Button>
                        </form>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> */}
                {/* <Grid item xs={12} style={{marginBottom: '3rem'}}>
                  <StaticImage src='../assets/images/Pond Liner Repair_Andreatta Waterscapes_Grants Pass Oregon.jpg' layout='constrained' alt='Pond Liner Repair Grants Pass Oregon Andreatta Waterscapes' quality={70} loading="lazy" height={700} className={classes.ftImg} objectFit='contain' />
                </Grid> */}
                <Grid item xs={12} style={{marginBottom: '3rem'}}>
                  <StaticImage src='../assets/images/Pond-Liner-Repair_Andreatta-Waterscapes_Grants-Pass-Oregon_compress.jpg' layout='fullWidth' alt='Pond Liner Repair Grants Pass Oregon Andreatta Waterscapes' quality={50} loading="lazy" height={300} className={classes.ftImg} objectFit='contain' />
                </Grid>
           
                <Grid item xs={12}>
                  <SidebarTestimonials />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Grid container justifyContent="center">
                    <Button
                      variant="contained"
                      // color="secondary"
                      component={Link}
                      to="/contact/"
                      className={classes.contactBtn}
                    >
                      Contact Today
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
